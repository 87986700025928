import axios from "axios";

export const LOGIN_URL = 'https://development.summerbooking.it/api/auth/signin';
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "https://development.summerbooking.it/api/auth/forgotpassword";
export const PASSWORD_RESET_URL = "https://development.summerbooking.it/api/auth/resetpassword"
export const ME_URL = "api/me";

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  let user = JSON.parse(localStorage.getItem("signInUser"))
  return user
}
export function resetAdminPassword(email, password) {
  return axios.post(PASSWORD_RESET_URL, { email, password });
}

