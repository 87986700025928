import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import axios from "axios";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordType, setPwdType] = useState("password");
  const [pwdIcon, setPwdIcon] = useState("far fa-eye-slash");
  const [password, setPwd] = useState("");
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Required .com/in/co")
      .min(3, "Minimum 3 symbols")
      .max(30, "Maximum 30 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Password should have atleast one uppercase , lowercase,  special character and minimum 6 symbols ")
      .max(15, "Password should have atleast one uppercase , lowercase,  special character and maximum 15 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,

    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email.toLowerCase(), values.password)
          .then(res => {
            // console.log(accessToken, "accessToken");
            disableLoading();
            if (res.data["status"]) {
              let user = res.data
              console.log("user",user)
              if(res.data.roles === "systemadmin"){
                localStorage.setItem("signInUser", JSON.stringify(user));
                const accessToken = JSON.parse(localStorage.getItem("signInUser")) ? JSON.parse(localStorage.getItem("signInUser")).accessToken : '';
                localStorage.setItem("accessToken", accessToken);
                let accessUserToken = res.data["accessToken"]
                // localStorage.setItem("signInUser", JSON.stringify(user));
                props.login(accessUserToken, user);
              }else{
                   setErrorMsg("Unauthorized Access")
              }
              // localStorage.setItem("signInUser", JSON.stringify(user));
              // const accessToken = JSON.parse(localStorage.getItem("signInUser")) ? JSON.parse(localStorage.getItem("signInUser")).accessToken : '';
              // localStorage.setItem("accessToken", accessToken);
              // let accessUserToken = res.data["accessToken"]
              // // localStorage.setItem("signInUser", JSON.stringify(user));
              // props.login(accessUserToken, user);
            } else {
              setErrorMsg(res.data.message)
            }

          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  const passwordToggle = (e) => {
    const type = passwordType === 'password' ? 'text' : 'password';
    const icon = pwdIcon === 'far fa-eye-slash' ? 'far fa-eye' : 'far fa-eye-slash';
    setPwdType(type)
    setPwdIcon(icon)
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}

      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {errorMsg !== "" ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{errorMsg}</div>
          </div>
        ) : (
          <></>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">

          <input
            placeholder="Password"
            type={passwordType}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"

            {...formik.getFieldProps("password")}
          />
          <i class={pwdIcon} id="togglePassword"
            onClick={(e) => passwordToggle(e)}
            style={{
              marginTop: "-33px",
              float: "right",
              marginRight: "30px"
            }}></i>

          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>

      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
