import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../layout";
import { Demo3Dashboard } from "./Demo3Dashboard";

export function Dashboard() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            demo: objectPath.get(
                uiService.config,
                "demo"
            )
        };
    }, [uiService]);
    return <>
        {layoutProps.demo === 'demo3' && <Demo3Dashboard />}
    </>;
}
