import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { resetAdminPassword } from "../_redux/authCrud";

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        const decryptedString = cryptr.decrypt(this.props.match.params.token);
        this.state = {
            token: this.props.match.params.token,
            decryptedString: decryptedString,
            password: "",
            changepassword: "",
            passwordError: "",
            changepasswordError: "",
            passwordMatchError: "",
            resetPasswordMessage: "",
            resetErrorPasswordMessage: ""
        };
    }

    handlepasswordChange = (evt) => {
        this.setState({ password: evt.target.value });
    }

    handleChnagepasswordChange = (evt) => {
        this.setState({ changepassword: evt.target.value });
    }

    validatePasswordMatch = (evt) => {
        if (evt.target.value !== this.state.password) {
            this.setState({ changepasswordError: "Confirm password should match with new password" })
        } else {
            this.setState({ changepasswordError: "" })
        }
    }

    _validateResetPassword = () => {
        var passwordError = '';
        var changepasswordError = ""

        if (this.state.password === '') {
            passwordError = 'Password is required';
        }
        if (this.state.changepassword === '') {
            changepasswordError = 'Confirm password is required';
        }
        if (this.state.changepassword !== this.state.password) {
            changepasswordError = 'Confirm password should match with new password';
        }
        if (changepasswordError !== '' || passwordError !== '') {
            this.setState({
                changepasswordError: changepasswordError,
                passwordError: passwordError,
            });
        } else {
            this.setState({
                changepasswordError: '',
                passwordError: '',
            });
            this.resetPassword();
        }
    }

    resetPassword = () => {
        resetAdminPassword(
            this.state.decryptedString,
            this.state.password
        ).then(response => {
            if (response.data.status) {
                this.setState({ resetPasswordMessage: response.data.message })
                setTimeout(() => {
                    this.props.history.push("/auth/login");
                }, 5000);

            } else {
                this.setState({ resetErrorPasswordMessage: response.data.message })
            }
        }).catch(function (error) {
            console.log(error)
        });
    }

    render() {
        const { password, changepassword, passwordError, changepasswordError, passwordMatchError, resetPasswordMessage, resetErrorPasswordMessage } = this.state
        const isDisable = passwordError != "" && changepasswordError !== "" && passwordMatchError !== ""
        return (
            <div className="login-form login-signin" style={{ display: "block" }}>
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                        Reset Password
                </h3>
                </div>
                <form>
                    {/* begin: Alert */}
                    {resetPasswordMessage && (
                        <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                            <div className="alert-text font-weight-bold">{resetPasswordMessage}</div>
                        </div>
                    )}
                    {resetErrorPasswordMessage && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{resetErrorPasswordMessage}</div>
                        </div>
                    )}
                    {/* end: Alert */}
                    {/* begin: Password */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="password"
                            onChange={this.handlepasswordChange}
                        />

                        {passwordError && passwordError ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block" style={{ color: "red" }}>{passwordError}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Password */}

                    {/* begin: Confirm Password */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Confirm Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="changepassword"
                            onChange={this.handleChnagepasswordChange}
                            onBlur={this.validatePasswordMatch}
                        />

                        {changepasswordError && changepasswordError ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block" style={{ color: "red" }}>
                                    {changepasswordError}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Confirm Password */}

                    <div className="form-group d-flex flex-wrap flex-center">
                        <button
                            type="button"
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            onClick={this._validateResetPassword}
                        >
                            Submit
                        </button>
                        <Link to="/auth/login">
                            <button
                                type="button"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                Cancel
            </button>
                        </Link>
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(ResetPassword);
